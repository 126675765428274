import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import ArticleBottomBar from '@sport1/news-styleguide/ArticleBottomBar'
import { AdType, ComponentType } from '@sport1/types/web'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import {
    ArticleInfo,
    ArticleInfoWithArticle,
    ArticleBottomNavigationProps,
    OnToolbarClick,
} from './types'
import AdSlot from '@/components/Ads/AdSlot'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { useObserveElementOnTop } from '@/hooks/useObserveElementOnTop'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import { SizeTypes } from '@/types/size'

const ArticleBottomNavigation = ({ recommendedArticles }: ArticleBottomNavigationProps) => {
    const isVisible = useObserveElementOnTop({ elementId: 'article-author-area' })
    const { trackTeaserClick, trackTeaserImpression } = useTracking()
    const { currentDevice } = useMediaQueryContext()

    const [showedArticles, setShowedArticles] = useState<string[]>([])
    const [toolbarArticle, setToolbarArticle] = useState<ArticleInfo>()
    const toolbarTestID = 'article-bottom-toolbar'
    const onToolbarClick = useCallback(
        ({ id, slug, isArticleClick = false }: OnToolbarClick) => {
            trackTeaserClick({
                advertiser_id: id,
                campaign: isArticleClick ? 'artikel' : 'home',
                creation: 'article-toolbar',
                variant: 'article-toolbar',
                format: 'automated | latest',
            })
            if (isArticleClick) {
                const tempShowedArticles = [...showedArticles]
                if (tempShowedArticles.length === 5) {
                    tempShowedArticles.pop()
                }
                tempShowedArticles.unshift(slug)
                sessionStorage.setItem('showedArticles', JSON.stringify(tempShowedArticles))
            }
            window.location.href = slug
        },
        [showedArticles, trackTeaserClick]
    )

    useEffect(() => {
        const sessionShowedArticles = sessionStorage.getItem('showedArticles')
        let showedArticles: string[] = []
        if (sessionShowedArticles) {
            const parsedArticles = JSON.parse(sessionShowedArticles)
            if (Array.isArray(parsedArticles)) {
                showedArticles = parsedArticles
                setShowedArticles(parsedArticles)
            }
        }
        const recArticle = recommendedArticles?.find(
            article =>
                article.slug &&
                article.title &&
                article.imageUrl &&
                !showedArticles.includes(article.slug)
        )
        // showArticleNavigation is false by default.
        // We need infomation about the device, because without it, it can send the wrong tracking
        setToolbarArticle(
            recArticle
                ? {
                      showArticleNavigation: false,
                      articleInfo: {
                          title: recArticle.title,
                          imageUrl: recArticle.imageUrl,
                          slug: recArticle.slug,
                          id: recArticle.id,
                          type:
                              recArticle?.type === ComponentType.ARTICLE_VIDEO
                                  ? 'VIDEO'
                                  : 'ARTICLE',
                      } as ArticleInfoWithArticle['articleInfo'],
                  }
                : { showArticleNavigation: false }
        )
    }, [setShowedArticles, recommendedArticles])

    useEffect(() => {
        if (toolbarArticle?.articleInfo && currentDevice) {
            // Here we set the appropriate value for showArticleNavigation
            setToolbarArticle(
                prev =>
                    ({
                        ...prev,
                        showArticleNavigation: currentDevice !== SizeTypes.DESKTOP,
                    }) as ArticleInfo
            )
        }
    }, [currentDevice, toolbarArticle?.articleInfo])

    useEffect(() => {
        const toolbar = document.querySelector(`[data-testid="${toolbarTestID}"]`)
        if (isVisible && toolbarArticle?.showArticleNavigation && toolbar) {
            trackTeaserImpression({
                advertiser_id: toolbarArticle.articleInfo.id,
                event: 'teaser.impression',
                campaign: 'artikel',
                creation: 'article-toolbar',
                variant: 'article-toolbar',
                format: 'automated | latest',
            })
        }
    }, [isVisible, toolbarArticle, trackTeaserImpression])

    useEffect(() => {
        const footer = document.getElementById('sport1-footer-container')
        const specialClass = 'article-footer-special-class'
        if (footer) {
            if (toolbarArticle?.showArticleNavigation) {
                footer.classList.add(specialClass)
            } else {
                footer.classList.remove(specialClass)
            }
            return () => {
                footer.classList.remove(specialClass)
            }
        }
    }, [toolbarArticle])

    if (!toolbarArticle || !toolbarArticle.showArticleNavigation) return null

    return (
        <NonFlexingContainer
            id="article-bottom-navigation-container"
            display="flex"
            width="100%"
            centerHorizontal
            className={clsx(
                'fixed bottom-0 left-0 right-0 z-50 transition ease-linear duration-200',
                {
                    'translate-y-[100%]': !isVisible,
                }
            )}
            testID="article-bottom-navigation-container"
        >
            <NonFlexingContainer maxWidth="1040px" width="100%">
                <AdSlot
                    id="stickyfooter"
                    ad={{
                        adKeys: [],
                        adType: AdType.DISPLAY,
                    }}
                />
                <NonFlexingContainer backgroundColor="black">
                    <NonFlexingContainer
                        testID="article-bottom-bar"
                        height={['60px', '80px', '100px']}
                        zIndex={50}
                        display="flex"
                        id="piano_article-bottom-bar"
                    >
                        <ArticleBottomBar
                            testID={toolbarTestID}
                            size={['S', 'M', 'L']}
                            onPressHome={() => onToolbarClick({ slug: '/', id: '' })}
                            articleType={toolbarArticle.articleInfo.type}
                            onPressArticle={() =>
                                onToolbarClick({
                                    ...toolbarArticle.articleInfo,
                                    isArticleClick: true,
                                })
                            }
                            articleImage={toolbarArticle.articleInfo.imageUrl}
                            articleDescription={toolbarArticle.articleInfo.title}
                        />
                    </NonFlexingContainer>
                </NonFlexingContainer>
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default ArticleBottomNavigation
